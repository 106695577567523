






























import Vue from 'vue'
import ModalHeader from '../shared/ModalHeader.vue'
import getSymbolFromCurrency from 'currency-symbol-map'
import CurrencyInput from '../shared/CurrencyInput.vue'
import { Price, CurrencyEnum } from '../../../../shared/types/types'

export default Vue.extend({
  name: 'CurrenciesModal',
  components: {
    ModalHeader,
    CurrencyInput,
  },
  props: {
    currencies: {
      type: Array,
      required: true,
    },
    save: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      isLoading: false,
      localCurrencies: JSON.parse(JSON.stringify(this.currencies)),
    } as {
      isLoading: boolean
      localCurrencies: Price[]
    }
  },
  mounted() {
    console.log(this.localCurrencies)
  },
  methods: {
    getSymbolFromCurrency,
    updateCurrency(currency: CurrencyEnum, amount: number) {
      const relevantPriceIndex = this.localCurrencies.findIndex(
        (price) => price.currency === currency
      )

      if (relevantPriceIndex === -1) return

      this.localCurrencies.splice(relevantPriceIndex, 1, {
        currency,
        amount,
      })
    },
    close() {
      this.$emit('close')
    },
    async handleClick() {
      this.isLoading = true

      try {
        await this.save(this.localCurrencies)

        this.close()
      } catch (error) {
        if (error instanceof Error) {
          console.error(error.message)
        } else {
          console.error(error)
        }
      } finally {
        this.isLoading = false
      }
    },
  },
})
